






































































































import { Component, Vue } from "vue-property-decorator";
import { IconsType, UpdateUsersStatus } from "@/types";
import { mdiClose, mdiPaperclip, mdiRefresh, mdiUploadOutline } from "@mdi/js";
import { namespace } from "vuex-class";
const AdminModule = namespace("AdminModule");
const AppModule = namespace("AppModule");
const size = 16 * 1024 * 1024;
const sizeText = "16 MiB";
@Component
export default class CardUserUpload extends Vue {
  //
  // COMPUTED
  @AppModule.Getter
  public smallScreen!: string;
  @AdminModule.State
  public updateUsersStatus!: UpdateUsersStatus;
  get isPending(): boolean {
    return (
      this.updateUsersStatus && this.updateUsersStatus.status === "pending"
    );
  }
  //
  // DATA
  private dialog = false;
  private valid = false;
  private file: File | null = null;
  private error = false;
  private fileInputId = "file-input-users";
  private refreshing = false;
  private uploading = false;
  private truncateLength = 30;
  private accept = [
    "application/vnd.ms-excel",
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ];
  private rules = [
    (t: File) =>
      !!t || this.$t("pages.admin.options.upload-users.rules.required"),
    (t: File) =>
      (!!t && this.accept.includes(t.type)) ||
      this.$t("pages.admin.options.upload-users.rules.type"),
    (t: File) =>
      (!!t && t.size <= size) ||
      this.$t("pages.admin.options.upload-users.rules.size", { s: sizeText })
  ];
  private icons: IconsType = {
    upload: mdiUploadOutline,
    refresh: mdiRefresh,
    close: mdiClose,
    file: mdiPaperclip
  };
  //
  // METHODS
  public statusToAlertType(status: string) {
    switch (status) {
      case "success":
        return "success";
      case "warning":
        return "warning";
      case "error":
        return "error";
      case "pending":
        return "info";
      default:
        return "info";
    }
  }
  toLocaleTime(time: string) {
    return new Date(time).toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    });
  }
  public upload() {
    this.uploading = true;
    this.error = false;
    setTimeout(async () => {
      if (this.valid) {
        const res = await this.$store.dispatch(
          "AdminModule/updateUsers",
          this.file
        );
        if (!res) this.error = true;
        else {
          this.refreshStatus();
          this.file = null;
        }
      }
      this.uploading = false;
    });
  }
  public refreshStatus() {
    this.refreshing = true;
    setTimeout(async () => {
      await this.$store.dispatch("AdminModule/getUpdateUserStatus");
      this.refreshing = false;
    });
  }
  public onPrependInnerClick() {
    document.getElementById(this.fileInputId)?.click();
  }
  //
  // HOOKS
  created() {
    this.refreshStatus();
  }
}
